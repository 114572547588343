import { IEnvironment } from './models/environment.model';

export const environment: IEnvironment = {
  name: 'staging',
  tenantId: 'luminapain-staging-1itzn',
  apiURL: 'https://api.luminapain-staging.com',
  graphQLEndpoint: '/graphql/mobile',
  diagnosesEndpoint: '/mgmt/diagnoses',
  firebaseConfig: {
    apiKey: 'AIzaSyB7o9VuUhHuCcGNrQVgL4emdHjtZNL6ozw',
    authDomain: 'luminapain.firebaseapp.com',
    projectId: 'luminapain',
    storageBucket: 'luminapain.appspot.com',
    messagingSenderId: '981932162284',
    appId: '1:981932162284:web:a98e898dc370cf6b9239c9',
    signInFlow: 'popup',
  },
  nylasConfig: {
    nylasUrl: 'https://api.eu.nylas.com/v3/grants',
    nylasApiKey:
      'nyk_v0_JO0iyDsDXAM349VP7Y8l6fIQjsj5rNG30qbj2nzkVSo5eibylQhgl9c4lmrmXbOY',
    nylasGrantId: '53aa676a-4fea-42a7-8554-849b69f81525',
  },
};
